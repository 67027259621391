body {
  background-color: #f5f7fa !important;
  display: contents !important;
}
html, body {
  min-width: 0px !important;
  overflow-y: scroll !important;
}
.audio-option-menu__pop-menu, 
.video-option-menu__pop-menu, 
.security-option-menu__pop-menu, 
.more-button__pop-menu, 
.sharing-setting-dropdown-menu-container__popup-menu {
  display: none !important;
}
.sharer-controlbar-container__main-content {
  transform: translate(329.5px, 5px) !important;
}
.wc-tooltip.sharing-performant-tooltip{
  top: -165px !important;
}
/* Chat Activo */
#wc-container-left.show-participants {
  width: 70% !important;
}
#wc-container-right {
  width: 30% !important;
}
/* Pantalla de Carga */
#wc-content {
  width: 100% !important;
}
#zmmtg-root {
  background-color: white !important;
  position: relative !important;
  display: inline-block !important;
  width: 100% !important;
} 
#content_container {
  position: relative !important;
  display: inline-block !important;
  width: 100% !important;
  /* height: 800px !important; */
}
.root-inner, .meeting-client, 
.meeting-client-inner, .meeting-app, 
.video-share-layout {
  position: relative !important;
  display: inline-block !important;
  width: 100% !important;
}
.single-main-container__video-frame,
.speaker-active-container__video-frame {
  position: absolute;
}
.sharee-container__canvas, 
.sharee-container__canvas-outline {
  display: inline-block !important;
}
/* Pantalla Flotante */
#wc-loading {
  position: relative !important;
  zoom: 98% !important;
}
#wc-leave, .full-screen-icon,
.meeting-info-container,
.suspension-window-container {
  display: none !important;
}
.join-dialog[role=presentation] {
  align-items: center !important;
  padding: 50px !important;
  width: 100% !important;
  bottom: 75px !important;
}
/* Pantallas de Reunión y Checkout */
.Banner {
  margin-left: 25px;
}
.BannerText {
  font-weight: bold;
}

.ZoomRoomContainer {
  width: 100% !important;
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
  /* display: flex;
  align-items: center; */
}
.container-preview {
  margin: 50px !important;
}

.ZoomRoomContent {
  float: left;
  width: 70% !important;
  background-color: white !important;
  zoom: 70% !important;
}
.ecwid {
  /* height: 57vh; */
  height: 100%;
  max-height: 450px;
}

.header-buttons {
  zoom: 75% !important;
  display: flex !important;
  justify-content: right !important;
  margin-bottom: 30px !important;
  margin-right: 20px !important;
}
.header-buttons1 {
  zoom: 75% !important;
  /* position: relative !important; */
  /* display: flex !important; */
  /* margin-right: 100px !important; */
  /* align-items: right !important; */
  /* justify-content: right !important; */
  justify-content: right !important;
  align-items: right !important;
  margin-bottom: 30px !important;
  /* margin-top: 30px !important; */
  margin-right: 20px !important;
}
.my-store-75812399 {
  /* position: absolute !important; */
  margin-top: 75px !important;
}
#divLeaveMeeting {
  display: none;
  height: 75px !important;
}
/* Logo Checkout */
.ec-cart-widget {
  display: flex !important;
  justify-content: center !important;
}
.sidebarShowMenu, .sidebarShowMenuURL {
  display: block;
  float: right;
  width: 30% !important;
  height: 550px;
  background-color: white !important;
  cursor: pointer;
  font-weight: bold;
  overflow: hidden;
}
.ec-minicart__icon {
  width: 36px !important;
  height: 36px !important;
}
.ec-minicart__counter {
  display: block !important;
  top: 5px !important;
  left: 35px !important;
  font-size: 14px !important;
}
.cart-not-empty {
  opacity: 0 !important;
}

.media-preview-container {
  zoom: 125% !important;
}

#media-preview-tooltip-container {
  bottom: 150px !important;
}

@media (max-width: 412px) {
  html, body {
    zoom: 75% !important;
  }
  .form-control__button-text {
    font-size: 1.5vw !important;
    line-height: 3vh !important;
  }

  h1 {
    font-size: 5vw !important;
    line-height: 3vh !important;
  }
  h2 {
    font-size: 4.5vw !important;
    line-height: 3vh !important;
  }
  h3, h4, a, div label, div, p, span {
    font-size: 4vw !important;
    line-height: 3vh !important;
  }
  a h5, div button, div h5, span span, div p, button span.form-control__button-text, button, div span, label, i, a div {
    font-size: 3vw !important;
    line-height: 3vh !important;
  }
  div span.MuiTypography-root {
    font-size: 2.5vw !important;
  }
  h5 {
    font-size: 2vw !important;
    line-height: 3vh !important;
  }
  div.MuiDialogContent-root.css-ayxoqt-MuiDialogContent-root 
  div.MuiGrid-root.MuiGrid-container.css-11lq3yg-MuiGrid-root 
  div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-12.css-1w8ykt7-MuiGrid-root {
    font-size: 2vw !important;
    padding: 0px !important;
    padding-left: 0px !important;
  }
  /* .single-main-container__canvas {
    margin-top: -25% !important;
  } */
}
@media (min-width: 413px) and (max-width: 720px) {
  html, body {
    zoom: 75% !important;
  }
  .header-buttons, .ec-cart-widget {
    zoom: 125% !important;
  }

  .form-control__button-text {
    font-size: 2vw !important;
    line-height: 5vh !important;
  }

  h1 {
    font-size: 5.5vw !important;
    line-height: 5vh !important;
  }
  h2 {
    font-size: 5vw !important;
    line-height: 5vh !important;
  }
  h3, h4, a, div label, div, p, span {
    font-size: 4.5vw !important;
    line-height: 5vh !important;
  }
  a h5, div button, div h5, span span, div p, button span.form-control__button-text, button, div span, label, i, a div {
    font-size: 3.5vw !important;
    line-height: 5vh !important;
  }
  div span.MuiTypography-root {
    font-size: 3vw !important;
  }
  h5 {
    font-size: 2.5vw !important;
    line-height: 5vh !important;
  }
  div.MuiDialogContent-root.css-ayxoqt-MuiDialogContent-root 
  div.MuiGrid-root.MuiGrid-container.css-11lq3yg-MuiGrid-root 
  div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-12.css-1w8ykt7-MuiGrid-root {
    font-size: 2.5vw !important;
    padding: 0px !important;
    padding-left: 0px !important;
  }
  /* .single-main-container__canvas {
    margin-top: -45% !important;
  } */
}
@media (min-width: 721px) and (max-width: 900px) {
  html, body {
    zoom: 100% !important;
  }
  .header-buttons, .ec-cart-widget {
    zoom: 125% !important;
  }
  /* .single-main-container__canvas {
    margin-top: -40% !important;
  } */
}
@media (max-width: 900px) {
  .footer__leave-btn-container, .footer__leave-btn, .leave-meeting-options__btn {
    width: 100%;
    height: 50px !important;
    line-height: 50px !important;
    margin-left: 50%;
    transform: translateX(-45%);
  }
  .single-main-container__video-frame,
  .speaker-active-container__video-frame {
    display: flex !important;
    justify-content: center !important;
    width: 100% !important;
    /* height: 75% !important; */
  }
  .sharee-container__viewport {
    margin-top: 0px !important;
    width: 100% !important;
    /* height: auto !important; */
  }
  .ZoomRoomContent {
    width: 100% !important;
  }
  .sidebarShowMenu, .sidebarShowMenuURL {
    width: 100% !important;
  }
  .ec-grid {
    zoom: 150% !important;
  }
  .ReactModal__Content {
    /* width: 90% !important; */
    zoom: 100% !important;
  }
  /* button.suspension-window-container__tab {
    display: block !important;
  } */
  /* .suspension-window-container__tabs--show {
    display: block !important;
  } */
  .common-window.react-draggable {
    width: 90% !important;
  }
  /* .footer__leave-btn-container {
    display: block !important;
  } */
  /* .single-main-container__canvas {
    width: 90% !important;
  } */
}